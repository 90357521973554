import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Tooltip from "@mui/material/Tooltip";
import React, { Fragment, useEffect, useState } from "react";

import OT from "@opentok/client";
import * as VideoExpress from "@vonage/video-express";
import moment from "moment";
import { BiMessageDetail } from "react-icons/bi";
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsTelephoneFill,
} from "react-icons/bs";
import {
  FaVideo,
  FaVideoSlash,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import {
  MdDesktopWindows,
  MdOutlineDesktopAccessDisabled,
  MdSettings,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { doc } from "firebase/firestore";
import ChatMessagesInCall from "../../components/Chat/ChatMessagesInCall";
import NotifLiveComp from "../../components/Meeting/NotifLiveComp";
import ModalSelectDevicesRoom from "../../components/modals/ModalSelectDevicesRoom";
import ModalTemplate from "../../components/modals/ModalTemplate";
import Timer from "../../components/Timer";
import NoLayout from "../../layouts/NoLayout";
import {
  chatRoomsGetByUserAndStreamer,
  chatRoomsUpdateViewStatus,
} from "../../utils/Firebase/chatRooms";
import { db } from "../../utils/Firebase/firebase";
import {
  meetingGetById,
  meetingUpdateStatus,
} from "../../utils/Firebase/meetings";
import {
  roomGetByInstantMeetId,
  roomGetByMeetId,
  roomGetParticipantById,
  roomJoin,
} from "../../utils/Firebase/rooms";
import { errorToast, successToast } from "../../utils/toasts";
import {
  instantMeetingGetById,
  instantMeetingUpdateStatus,
  instantMeetingUpdateTimeUsed,
} from "../../utils/Firebase/instantMeetings";

function InstantMeetingLive() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [ringOfLoading, setRingOfLoading] = useState(false);
  const [ringOfModal, setRingOfModal] = useState(false);
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondesLeft, setSecondesLeft] = useState(0);
  const [room, setRoom] = useState();
  const [camera, setCamera] = useState();
  const [screen, setScreen] = useState();
  const [roomLive, setRoomLive] = useState();
  const [boolVideo, setBoolVideo] = useState(true);
  const [boolAudio, setBoolAudio] = useState(true);
  const [audioDevices, setAudioDevices] = useState();
  const [audioActualDevice, setActualAudioDevice] = useState();
  const [videoActualDevice, setActualVideoDevice] = useState();
  const [videoDevices, setVideoDevices] = useState();
  const [boolSharingScreen, setBoolSharingScreen] = useState(false);
  const [choiceDeviceBool, setChoiceDeviceBool] = useState(false);
  const [soundBool, setSoundBool] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [ended, setEnded] = useState(false);
  const [meeting, setMeeting] = useState(null);
  const [roomChat, setRoomChat] = useState(null);
  const [messagesRoom, setMessagesRoom] = useState(false);
  const [participants, setParticipants] = useState();
  const [notif, setNotif] = useState(false);
  const [joinError, setJoinError] = useState(false);
  const { id } = useParams();
  const now = moment().format("x");
  const publisher = null;

  const endCall = async () => {
    await instantMeetingUpdateStatus(meeting.id, "ENDED");
    if (doc(db, "users", user.uid) === meeting.userRef) {
      window.location.replace("/meetings");
    } else {
      window.location.replace("/streamer/accueil");
    }
    successToast("L'appel vient de se terminer");
  };

  const endCallV2 = async () => {
    console.log(id);
    await instantMeetingUpdateStatus(id, "ENDED");
    if (doc(db, "users", user.uid) === meeting.userRef) {
      window.location.replace("/meetings");
    } else {
      window.location.replace("/streamer/accueil");
    }
    successToast("L'appel vient de se terminer");
  };

  const transferFunds = async (hours, minutes) => {
    console.log("transferFunds");
    // Check if user as funds for *2
    const res = await instantMeetingGetById(id);
    console.log(res);
    console.log(res.user.amount);
    const usedMinutes = parseInt(60 * hours) + parseInt(minutes);
    if (
      parseFloat(res.priceMinute) * (usedMinutes + 5) <
      parseFloat(res.user.amount)
    ) {
      console.log(usedMinutes);
      // Continue and change meet timer
      await instantMeetingUpdateTimeUsed(id, usedMinutes);
    } else {
      await instantMeetingUpdateTimeUsed(id, usedMinutes);
      endCallV2();
    }
  };

  /*
   * Init and manage the timer before and during the meet
   */
  const initializeTimer = (el) => {
    console.log("el (initializeTimer) : ", el);
    const time = el / 1000;
    const hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor(time / 60 - hours * 60);
    let secondes = Math.abs(Math.floor(time % 60));
    setMinutesLeft(minutes);
    setSecondesLeft(secondes);
    setHoursLeft(hours);
    const interval = setInterval(() => {
      if (secondes + 1 >= 59) {
        if (minutes + 1 >= 59) {
          setHoursLeft(hours + 1);
          setMinutesLeft(0);
          setSecondesLeft(0);
          if (parseInt(hours) >= 12) {
            endCallV2();
          }
        } else {
          minutes += 1;
          setMinutesLeft(minutes);
          secondes = 0;
          setSecondesLeft(secondes);
        }
        transferFunds(hours, minutes);
      } else {
        secondes += 1;
        setSecondesLeft(secondes);
      }
    }, 1000);
  };

  /*
   * Find and set all availables devices
   */
  const setDevices = () => {
    try {
      OT.getDevices((error, devices) => {
        console.log("Appareils : ", devices);
        // AUDIO
        const audioDevicesTemp = devices.filter(
          (el) => el.kind === "audioInput",
        );
        if (audioDevicesTemp && audioDevicesTemp.length) {
          setAudioDevices(audioDevicesTemp);
        }
        // VIDEO
        const videoDevicesTemp = devices.filter(
          (el) => el.kind === "videoInput",
        );
        if (videoDevicesTemp && videoDevicesTemp.length) {
          setVideoDevices(videoDevicesTemp);
        }
      });
    } catch (err) {
      console.log("Error : ", err);
      errorToast("Problème avec vos appareils");
    }
  };

  /*
   * Set the active audio device
   */
  const changeAudioDevice = async (value) => {
    try {
      await camera.setAudioDevice(value);
      setActualAudioDevice(value);
    } catch (err) {
      console.log("Error ", err);
      errorToast("Probleme lors du changement d'appareil audio");
    }
  };

  /*
   * Set the active video device
   */
  const changeVideoDevice = async (value) => {
    try {
      await camera.setVideoDevice(value);
      setActualVideoDevice(value);
    } catch (err) {
      console.log("Error ", err);
      errorToast("Probleme lors du changement d'appareil vidéo");
    }
  };

  /*
   * Manage the screen share
   */
  const handleShareScreen = () => {
    try {
      if (boolSharingScreen) {
        roomLive.stopScreensharing();
        setBoolSharingScreen(!boolSharingScreen);
      } else {
        roomLive
          .startScreensharing("screen-preview")
          .then(() => {
            setBoolSharingScreen(!boolSharingScreen);

            // Listen off share screen stop outside the webapp
            // (ex: click on stop sharing screen button on the current screen)
            screen.on("stopped", () => {
              setBoolSharingScreen(false);
            });
          })
          .catch((err) => console.log(err));
      }
    } catch (err) {
      console.log("Error ", err);
      errorToast("Problème avec le partage d'écran");
    }
  };

  /*
   * On/Off camera
   */
  const toggleVideo = () => {
    try {
      if (boolVideo) {
        camera.disableVideo();
      } else {
        camera.enableVideo();
      }
      setBoolVideo(!boolVideo);
    } catch (err) {
      console.log("Error ", err);
      errorToast("Problème avec le partage de la vidéo");
    }
  };

  /*
   * On/Off audio
   */
  const toggleAudio = () => {
    try {
      if (boolAudio) {
        camera.disableAudio();
      } else {
        camera.enableAudio();
      }
      setBoolAudio(!boolAudio);
    } catch (err) {
      console.log("Error ", err);
      errorToast("Problème avec le partage de l'audio");
    }
  };

  /*
   * On/Off sound of the webapp
   */
  function mutePage(bool, participants) {
    try {
      for (const [key, value] of Object.entries(roomLive.participants)) {
        console.log(roomLive.participants);
        if (value.camera) {
          if (!bool) {
            value.camera.disableAudio();
          } else {
            value.camera.enableAudio();
          }
        }
      }
      setSoundBool(bool);
    } catch (err) {
      console.log("Error : ", err);
      errorToast("Problème avec l'activation du son");
    }
  }

  /*
   * Init, join the live and set the differents devices
   */
  const startRoomLive = async () => {
    const roomLiveTemp = new VideoExpress.Room({
      apiKey: room.apiKey,
      sessionId: room.sessionId,
      token: room.sessionToken,
      roomContainer: "roomContainer",
      managedLayoutOptions: {
        cameraSubscriberContainer: "subscriber",
        cameraPublisherContainer: "publisher",
        layoutMode: "active-speaker",
      },
    });

    // Find availables devices for the call or error/quit
    let pass = true;
    for (const audioD of audioDevices) {
      for (const videoD of videoDevices) {
        if (pass) {
          try {
            await roomLiveTemp.join({
              publisherProperties: {
                audioSource: audioD.deviceId,
                videoSource: videoD.deviceId,
              },
            });
            setJoinError(false);
            pass = false;
          } catch (err) {
            console.log("Error : ", err);
          }
        }
      }
    }
    if (pass) {
      setJoinError(true);
      errorToast("Impossible de rejoindre le call");
    }
    setRoomLive(roomLiveTemp);
    setCamera(roomLiveTemp.camera);
    setScreen(roomLiveTemp.screen);
    const currentVideoDevice = await roomLiveTemp.camera.getVideoDevice();
    setActualVideoDevice(currentVideoDevice.deviceId);
    const currentAudioDevice = await roomLiveTemp.camera.getAudioDevice();
    setActualAudioDevice(currentAudioDevice.deviceId);
  };

  /*
   * Actions when a new participants join the call (new connexion)
   */
  const handleSignal = (signalEvent) => {
    console.log("signalEvent : ", signalEvent);
    if (signalEvent.data === "NewConnexion") {
      const soundB = document.querySelector("#soundBool").value;
      console.log(soundB);
      // mutePage(!soundB);
    }
  };

  const openRingOffModal = () => {
    setRingOfModal(true);
  };

  const leaveCall = async () => {
    try {
      setRingOfLoading(true);
      await instantMeetingUpdateStatus(meeting.id, "ENDED");
      setTimeout(() => {
        setRingOfLoading(false);
        if (doc(db, "users", user.uid) === meeting.userRef) {
          window.location.replace("/meetings");
        } else {
          window.location.replace("/streamer/accueil");
        }
        successToast("Vous venez de raccrocher");
      }, 2000);
    } catch (err) {
      console.log("Error : ", err);
      errorToast("Impossible de raccrocher");
    }
  };

  /**
   * Set messagerie to viewed
   */
  const displayMessagesRoom = async () => {
    if (!messagesRoom) {
      await chatRoomsUpdateViewStatus(
        roomChat.id,
        roomChat.user.uid === user.uid ? "user" : "streamer",
        true,
      );
      setNotif(false);
    }
    setMessagesRoom(!messagesRoom);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const getRoom = () => {
    console.log("ID", id);
    const interval = setInterval(() => {
      roomGetByInstantMeetId(id).then((roomEl) => {
        if (roomEl) {
          clearInterval(interval);
        }
        console.log("ROOM", roomEl);
        setRoom(roomEl);
      });
    }, 2000);
  };

  useEffect(() => {
    if (user && user.uid) {
      // Get meet and set the right page and/or the timer
      instantMeetingGetById(id).then((el) => {
        console.log("Meeting : ", el);
        // Check if the user who connected is in the meet
        if (user.uid !== el.user.uid && user.uid !== el.streamer.uid) {
          navigate("/");
          errorToast("Vous ne faites pas parti de ce meet.");
        }
        chatRoomsGetByUserAndStreamer(el.user, el.streamer).then((res) => {
          console.log("Chat room : ", res);
          setRoomChat(res[0]);
        });
        setMeeting({ ...el, id });
      });
      // Get room
      getRoom();

      // Get all devices
      setDevices();
    }
  }, [user]);

  useEffect(() => {
    if (meeting) {
      if (meeting.status === "ENDED") {
        setEnded(true);
      } else {
        console.log("acceptedAt", meeting.acceptedAt);
        initializeTimer(now - meeting.acceptedAt);
      }
    }
  }, [meeting]);

  useEffect(() => {
    if (waiting || ended) {
      console.log("WAITING - ENDED");
      return;
    }
    if (room && !room.sessionToken) {
      const intervalParticipants = setInterval(() => {
        roomGetParticipantById(room.id, user.uid).then((el) => {
          roomJoin({ userId: user.uid, roomId: room.id }).then((e) => {
            if (el && el.token) {
              clearInterval(intervalParticipants);
              const interval = setInterval(() => {
                clearInterval(interval);
                setRoom({ ...room, sessionToken: el.token });
              }, 2000);
            }
          });
        });
      }, 2000);
    }
    if (room && room.sessionToken) {
      startRoomLive();
    }
  }, [room]);

  useEffect(() => {
    if (waiting || ended) {
      return;
    }
    console.log(videoDevices);
    if (videoDevices && publisher) {
      for (let i = 0; i < videoDevices.length; i++) {
        room.camera
          .setVideoDevice(videoDevices[0].deviceId)
          .then((el) => {
            console.log("Done ", el);
            i = 10000;
          })
          .catch((err) => {
            console.log("Error ", err);
          });
      }
    }
  }, [publisher, videoDevices]);

  useEffect(() => {
    if (roomLive && roomLive.participants) {
      setParticipants(roomLive.participants);
      roomLive.on("connected", () => {
        console.log("Connected !");
        roomLive.signal({
          type: "NewConnexion",
          data: "NewConnexion",
        });
      });
      roomLive.on("participantJoined", (participant) => {
        console.log(roomLive.participants, participant);
        setParticipants({ ...roomLive.participants, participant });
      });
      roomLive.on("participantLeft", () => {
        endCallV2();
      });
    }
  }, [roomLive]);

  useEffect(() => {
    if (participants) {
      mutePage(!soundBool, participants);
    }
  }, [participants]);

  return (
    <div
      id="bg-call"
      className="flex w-full h-full box-border overflow-y-hidden bg-whitebg"
    >
      {joinError ? (
        <div className="flex flex-col justify-center w-full h-full">
          <p className="text-center text-xl font-semibold underline decoration-2 decoration-fuchsia-500 underline-offset-2 p-2">
            Erreur lors de la connexion au meet.
          </p>
          <p className="mb-2">
            Veuillez vérifier que vos appareils ne sont pas connectés à une
            autre application.
          </p>
          <p
            onClick={reloadPage}
            className="text-sm cursor-pointer hover:bg-fuchsia-200 hover:font-semibold p-1.5 rounded w-fit transition-all mx-auto"
          >
            Actualiser
          </p>
        </div>
      ) : (
        <div className="w-full h-full">
          {ended ? (
            <div className="flex flex-col justify-center w-full h-full">
              <p className="text-center text-xl font-semibold underline decoration-2 decoration-fuchsia-500 underline-offset-2 p-2">
                Le meet est fini.
              </p>
              <a
                aria-label="Retour accueil"
                href="/"
                className="text-sm hover:bg-fuchsia-200 hover:font-semibold p-1.5 rounded w-fit transition-all mx-auto"
              >
                Retour à l'accueil →
              </a>
            </div>
          ) : (
            <div className="w-full h-full">
              <div className="shadow sm:rounded-md bg-white px-4 py-4 overflow-hidden w-full h-full">
                {room && room.sessionToken ? (
                  <div className="w-full h-full flex">
                    <div className="w-full h-full flex flex-col gap-4 ">
                      <div className=" w-full h-full relative bg-text rounded-xl">
                        <div
                          id="roomContainer"
                          className="w-full h-full rounded-xl"
                        />
                      </div>
                      <div className="flex justify-center lg:justify-between">
                        <div className="flex lg:relative absolute z-30 lg:top-auto lg:left-auto top-8 left-8 items-center justify-center">
                          <Timer
                            hoursLeft={hoursLeft}
                            secondesLeft={secondesLeft}
                            minutesLeft={minutesLeft}
                          />
                        </div>
                        <div className="items-center flex gap-1.5 lg:gap-2 justify-center">
                          <Tooltip title="Partage de caméra" placement="top">
                            <button
                              type="button"
                              className={`h-10 w-10 lg:h-14 lg:w-14 shadow-lg ${
                                boolVideo
                                  ? "bg-gradient-to-tr from-teal-600 via-emerald-500 to-emerald-500"
                                  : "bg-gradient-to-tr from-orange-600 via-red-500 to-red-500"
                              } rounded-lg relative`}
                              onClick={toggleVideo}
                            >
                              {boolVideo ? (
                                <FaVideo
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              ) : (
                                <FaVideoSlash
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              )}
                            </button>
                          </Tooltip>
                          <Tooltip title="Partage du micro" placement="top">
                            <button
                              type="button"
                              className={`h-10 w-10 lg:h-14 lg:w-14 shadow-lg ${
                                boolAudio
                                  ? "bg-gradient-to-tr from-teal-600 via-emerald-500 to-emerald-500"
                                  : "bg-gradient-to-tr from-orange-600 via-red-500 to-red-500"
                              } rounded-lg relative`}
                              onClick={toggleAudio}
                            >
                              {boolAudio ? (
                                <BsFillMicFill
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              ) : (
                                <BsFillMicMuteFill
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              )}
                            </button>
                          </Tooltip>
                          <Tooltip title="Partage d'écran" placement="top">
                            <button
                              type="button"
                              disabled={boolSharingScreen === "none"}
                              className={`h-10 w-10 lg:h-14 lg:w-14 shadow-lg ${
                                boolSharingScreen
                                  ? "bg-gradient-to-tr from-teal-600 via-emerald-500 to-emerald-500"
                                  : "bg-gradient-to-tr from-orange-600 via-red-500 to-red-500"
                              } rounded-lg relative`}
                              onClick={handleShareScreen}
                            >
                              {boolSharingScreen ? (
                                <MdDesktopWindows
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              ) : (
                                <MdOutlineDesktopAccessDisabled
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              )}
                            </button>
                          </Tooltip>
                          <Tooltip title="Raccrocher" placement="top">
                            <button
                              type="button"
                              className="lg:h-16 lg:w-16 h-12 w-12 shadow-xl bg-red-500 hover:bg-red-700 transition-all duration-150 rounded-lg relative mx-1 lg:mx-3"
                              onClick={openRingOffModal}
                            >
                              <BsTelephoneFill
                                color="white"
                                className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                size={26}
                              />
                            </button>
                          </Tooltip>
                          <Tooltip
                            title={`${
                              soundBool ? "Désactiver" : "Activer"
                            } le son`}
                            placement="top"
                          >
                            <button
                              type="button"
                              id="soundBool"
                              value={soundBool}
                              className={`h-10 w-10 lg:h-14 lg:w-14 shadow-lg ${
                                soundBool
                                  ? "bg-gradient-to-tr from-teal-600 via-emerald-500 to-emerald-500"
                                  : "bg-gradient-to-tr from-orange-600 via-red-500 to-red-500"
                              } rounded-lg relative`}
                              onClick={() => mutePage(!soundBool)}
                            >
                              {soundBool ? (
                                <FaVolumeUp
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              ) : (
                                <FaVolumeMute
                                  color="white"
                                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                  size={24}
                                />
                              )}
                            </button>
                          </Tooltip>
                          <Tooltip title="Paramètres" placement="top">
                            <button
                              onClick={() => setChoiceDeviceBool(true)}
                              type="button"
                              className="w-10 h-10 lg:h-14 lg:w-14 shadow-lg bg-gradient-to-tr from-gray-200 to-slate-200 rounded-lg relative"
                            >
                              <MdSettings
                                className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                size={28}
                              />
                            </button>
                          </Tooltip>
                          <Tooltip title="Messagerie" placement="top">
                            <button
                              onClick={() => displayMessagesRoom()}
                              type="button"
                              className="w-10 h-10 lg:h-14 lg:w-14 shadow-lg bg-fuchsia-500 hover:bg-fuchsia-600 transition-all duration-200 rounded-lg relative"
                            >
                              {roomChat && roomChat.id ? (
                                <NotifLiveComp
                                  messagesRoom={messagesRoom}
                                  setNotif={setNotif}
                                  notif={notif}
                                  room={roomChat}
                                />
                              ) : (
                                <div />
                              )}
                              <BiMessageDetail
                                color="white"
                                className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                                size={28}
                              />
                            </button>
                          </Tooltip>
                        </div>
                        <div className="lg:relative absolute z-30 rounded-xl p-1 bg-text overflow-hidden lg:bottom-auto lg:right-auto bottom-28 right-8 w-fit h-fit">
                          <div
                            className="h-fit w-fit rounded-xl overflow-hidden"
                            id="publisher"
                          />
                          <div
                            className="h-fit w-fit top-0 absolute z-20"
                            id="screen-preview"
                          />
                        </div>
                      </div>
                    </div>
                    {roomChat ? (
                      <Transition.Root show={messagesRoom} as={Fragment}>
                        <Dialog
                          as="div"
                          className="relative z-50"
                          onClose={setMessagesRoom}
                        >
                          <Transition.Child
                            unmount={false}
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed inset-0 overflow-hidden">
                            <div className="absolute inset-0 overflow-hidden">
                              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                  as={Fragment}
                                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                                  enterFrom="translate-x-full"
                                  enterTo="translate-x-0"
                                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                                  leaveFrom="translate-x-0"
                                  leaveTo="translate-x-full"
                                >
                                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl scrollbar-hide">
                                      <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                          <Dialog.Title className="text-lg font-medium text-gray-900">
                                            Messagerie
                                          </Dialog.Title>
                                          <div className="ml-3 flex h-7 items-center">
                                            <button
                                              type="button"
                                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                              onClick={() =>
                                                setMessagesRoom(false)
                                              }
                                            >
                                              <span className="sr-only">
                                                Close panel
                                              </span>
                                              <XMarkIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="relative flex-1 px-2 h-full">
                                        <ChatMessagesInCall
                                          textSendBtn={false}
                                          roomId={roomChat.id}
                                          room={roomChat}
                                          type={
                                            user.uid === roomChat.streamer.uid
                                              ? "streamer"
                                              : "user"
                                          }
                                          messagesRoom={messagesRoom}
                                        />
                                      </div>
                                    </div>
                                  </Dialog.Panel>
                                </Transition.Child>
                              </div>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                    ) : (
                      <div />
                    )}
                  </div>
                ) : (
                  <div className="h-full flex flex-col justify-center">
                    <p className="mb-2 font-semibold">
                      Vous êtes en train de rejoindre le meet
                    </p>
                    <progress className="self-center progress progress-primary w-56" />
                    <p
                      onClick={reloadPage}
                      className="text-sm my-2 cursor-pointer hover:bg-fuchsia-200 hover:font-semibold p-1.5 rounded w-fit transition-all mx-auto"
                    >
                      Actualiser
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <ModalTemplate
            setShowModal={setRingOfModal}
            showModal={ringOfModal}
            loading={ringOfLoading}
            successAction={leaveCall}
            successColor="danger"
            successActionTitle="QUITTER"
            title="Quitter le meet"
            desc="Voulez-vous vraiment quitter le meet ? Le meet prendra fin, et vous devrez en commencer un autre pour échanger à nouveau avec cet expert."
            errorActionTitle="Annuler"
            errorColor="cancel"
          />
          <ModalSelectDevicesRoom
            changeVideoDevice={changeVideoDevice}
            changeAudioDevice={changeAudioDevice}
            setActualAudioDevice={setActualAudioDevice}
            setActualVideoDevice={setActualVideoDevice}
            videoActualDevice={videoActualDevice}
            audioActualDevice={audioActualDevice}
            videoDevices={videoDevices}
            audioDevices={audioDevices}
            showModal={choiceDeviceBool}
            setShowModal={setChoiceDeviceBool}
          />
        </div>
      )}
    </div>
  );
}

export default NoLayout(InstantMeetingLive);
