import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./user/userSlice";
import { paymentSlice } from "./payment/paymentSlice";
import { notificationsSlice } from "./notifications/notificationsSlice";

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    payment: paymentSlice.reducer,
    notifications: notificationsSlice.reducer,
  },
});
