import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    amount: 0,
    card: {},
  },
  reducers: {
    setCard: (state, { payload }) => {
      state.card = payload;
    },
    setAmount(state, { payload }) {
      state.amount = payload;
    },
  },
});

export const { setCard, setAmount } = paymentSlice.actions;

export default paymentSlice.reducer;
