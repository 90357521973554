import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  collection,
  limit,
  addDoc,
  orderBy,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../firebase";
import randomId from "../../RandomId";

// Utils

const fetchUserAndStreamer = async (data) => {
  let response = { ...data };
  if (data.streamerRef) {
    const streamerSnap = await getDoc(data.streamerRef);
    if (streamerSnap.exists()) {
      response = { ...response, streamer: streamerSnap.data() };
    }
  }
  if (data.userRef) {
    const userSnap = await getDoc(data.userRef);
    if (userSnap.exists()) {
      response = { ...response, user: userSnap.data() };
    }
  }
  return response;
};

// Firebase functions

const chatRoomsGetByStreamer = async (streamer) => {
  if (!streamer || !streamer.uid) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms");
  const q = query(
    chatRoomsRef,
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
  );
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    data.push({ ...el.data(), id: el.id });
  });

  if (data && data.length) {
    const result = await Promise.all(
      data.map(async (room) => {
        const res = await fetchUserAndStreamer(room);
        return res;
      }),
    );
    return result;
  }
  return false;
};

const chatRoomsUnreadCountByStreamer = async (streamer) => {
  if (!streamer || !streamer.uid) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms");
  const q = query(
    chatRoomsRef,
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
    where("streamerView", "==", false),
  );
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    console.log("AA");
    data.push({ ...el.data(), id: el.id });
  });
  if (data && data.length) {
    const result = await Promise.all(
      data.map(async (room) => {
        const res = await fetchUserAndStreamer(room);
        return res;
      }),
    );
    return result;
  }
  return false;
};

const chatRoomsUnreadCountByUser = async (user) => {
  if (!user || !user.uid) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms");
  const q = query(
    chatRoomsRef,
    where("userRef", "==", doc(db, "users", user.uid)),
    where("userView", "==", false),
  );
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    console.log("AA");
    data.push({ ...el.data(), id: el.id });
  });
  if (data && data.length) {
    const result = await Promise.all(
      data.map(async (room) => {
        const res = await fetchUserAndStreamer(room);
        return res;
      }),
    );
    return result;
  }
  return false;
};

const chatRoomsGetByUser = async (user) => {
  if (!user || !user.uid) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms");
  const q = query(
    chatRoomsRef,
    where("userRef", "==", doc(db, "users", user.uid)),
  );
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    data.push({ ...el.data(), id: el.id });
  });

  if (data && data.length) {
    const result = await Promise.all(
      data.map(async (room) => {
        const res = await fetchUserAndStreamer(room);
        return res;
      }),
    );
    return result;
  }
  return false;
};

const chatRoomsUpdateViewStatus = async (id, type, value = true) => {
  if (!type || !id) {
    return false;
  }
  let views;
  if (type === "user") {
    views = { userView: value };
  } else {
    views = { streamerView: value };
  }
  console.log(views);
  const roomDoc = doc(db, "chatRooms", id);
  await setDoc(roomDoc, views, { merge: true });
  return true;
};

const chatRoomsGetMessagesById = async (id) => {
  if (!id) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms", id, "chats");
  const q = query(chatRoomsRef, orderBy("createdAt", "asc"), limit(25));
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    data.push({ ...el.data(), id: el.id });
  });
  return data;
};

const sendNewMessage = async (id, content, author) => {
  if (!id) {
    return false;
  }
  const chatRoomsRef = collection(db, "chatRooms", id, "chats");
  await addDoc(chatRoomsRef, {
    createdAt: moment().format("x"),
    author,
    content,
    view: false,
  });
};

const chatRoomsGetByUserAndStreamer = async (user, streamer) => {
  if (!user || !streamer) {
    return false;
  }
  const chatRoomRef = collection(db, "chatRooms");
  const q = query(
    chatRoomRef,
    where("userRef", "==", doc(db, "users", user.uid)),
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
  );
  const snap = await getDocs(q);
  const data = [];
  snap.forEach((el) => {
    data.push({ ...el.data(), id: el.id });
  });
  if (data && data.length) {
    const result = await Promise.all(
      data.map(async (room) => {
        const res = await fetchUserAndStreamer(room);
        return res;
      }),
    );
    return result;
  }
  return false;
};

const chatRoomsCreate = async (user, streamer) => {
  if (!user || !streamer) {
    return false;
  }
  const id = randomId();
  const chatRoomRef = doc(db, "chatRooms", id);
  await setDoc(chatRoomRef, {
    streamerRef: doc(db, "users", streamer.uid),
    userRef: doc(db, "users", user.uid),
    userView: true,
    streamerView: true,
  });
  return id;
};

export {
  chatRoomsGetByStreamer,
  chatRoomsCreate,
  chatRoomsGetMessagesById,
  chatRoomsGetByUser,
  sendNewMessage,
  chatRoomsGetByUserAndStreamer,
  chatRoomsUpdateViewStatus,
  chatRoomsUnreadCountByStreamer,
  chatRoomsUnreadCountByUser,
};
