import React from "react";

function NoLayout(WrappedComponent) {
  return function () {
    return (
      <div className="overflow-x-hidden overflow-y-auto h-screen w-screen">
        <WrappedComponent />
      </div>
    );
  };
}

export default NoLayout;
