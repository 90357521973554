import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc } from "firebase/firestore";
import { Toaster } from "react-hot-toast";
import { auth, db } from "./utils/Firebase/firebase";
import { userCheckEntries } from "./utils/Firebase/users";
import { setUser } from "./store/user/userSlice";
import RoutesApp from "./RoutesApp";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [display, setDisplay] = useState(false);

  onAuthStateChanged(auth, async (user) => {
    if (user && user.uid) {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      const {
        displayName,
        streamerTitle,
        phone,
        imgUrl,
        amount,
        ban,
        admin,
        obvyId,
        firstName,
        lastName,
        partner,
      } = userCheckEntries(data);
      const { uid, email } = user;
      dispatch(
        setUser({
          uid,
          email,
          displayName,
          imgUrl,
          phone,
          amount,
          streamerTitle,
          ban,
          admin,
          obvyId,
          firstName,
          lastName,
          partner,
        }),
      );
    }
    setDisplay(true);
  });

  return (
    <div
      className="App font-raleway w-screen h-screen bg-whitebg text-text"
      data-theme="basic"
    >
      <RoutesApp display={display} />
      <Toaster position="bottom-right" reverseOrder />
    </div>
  );
}

export default App;
