import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
import algoliasearch from "algoliasearch";

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

console.log("NODE_ENV : ", process.env.NODE_ENV);
console.log("REACT_APP_ENV : ", process.env.REACT_APP_ENV);

let algoliaClient;
let firebaseConfig;
let sitekey;
if (process.env.REACT_APP_ENV === "development") {
  /** PRE-PROD */
  // Initialize algolia
  algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID_PREPROD,
    process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY_PREPROD,
  );

  sitekey = process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY_PREPROD;

  // Initiliaze Firebase services
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY_PREPROD,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_PREPROD,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID_PREPROD,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_PREPROD,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_PREPROD,
    appId: process.env.REACT_APP_FIREBASE_APPID_PREPROD,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_PREPROD,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_PREPROD,
  };
} else {
  /** PROD */
  // Initialize algolia
  algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID_PROD,
    process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY_PROD,
  );

  sitekey = process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY_PROD;

  // Initiliaze Firebase services
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY_PROD,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_PROD,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID_PROD,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_PROD,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_PROD,
    appId: process.env.REACT_APP_FIREBASE_APPID_PROD,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID_PROD,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_PROD,
  };
}

const app = initializeApp(firebaseConfig);

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(sitekey),
  isTokenAutoRefreshEnabled: true,
});

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const database = getDatabase(app);

export default app;
export { auth, db, storage, analytics, functions, database, algoliaClient };
