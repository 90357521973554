import { collection, limit, orderBy, query } from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { MdSend } from "react-icons/md";

import { sendNewMessage } from "../../utils/Firebase/chatRooms";
import { db } from "../../utils/Firebase/firebase";
import BtnClassic from "../buttons/BtnClassic";

function ChatMessagesInCall({
  roomId,
  room,
  type,
  textSendBtn = true,
  bgColor = "bg-white",
}) {
  const [newMsg, setNewMsg] = useState("");
  /**
   * Automaticly fetch messages (all time in sync)
   * https://github.com/CSFrequency/react-firebase-hooks/blob/master/firestore/README.md
   */
  const [chats] = useCollectionData(
    query(
      collection(db, "chatRooms", roomId, "chats"),
      orderBy("createdAt", "desc"),
      limit(25),
    ),
  );

  const isStreamer = () => type === "streamer";

  const sendMessage = async () => {
    await sendNewMessage(roomId, newMsg, type);
    setNewMsg("");
  };

  const leftSideMsgs = (msg, r) => (
    <div key={msg.createdAt} className="p-3 rounded-lg">
      <div className="flex flex-col">
        <div className="flex flex-row items-center break-all">
          <div className="flex items-center justify-center h-10 w-10 overflow-hidden rounded-full bg-slate-200 shadow flex-shrink-0 mx-1">
            <img
              src={isStreamer() ? r.user.imgUrl : r.streamer.streamer.images[0]}
            />
          </div>
          <div className="relative break-words text-left ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
            <div>{msg.content}</div>
          </div>
        </div>
        <p className="text-xs text-left font-lato">
          {moment(msg.createdAt, "x").format("lll")}
        </p>
      </div>
    </div>
  );

  const rightSideMsgs = (msg, r) => (
    <div key={msg.createdAt} className="p-3 rounded-lg">
      <div className="flex items-center justify-start">
        <div className="flex flex-col break-all">
          <div className="flex flex-row items-center">
            <div className="flex items-center justify-center h-10 w-10 overflow-hidden rounded-full bg-purple-500 flex-shrink-0 mx-1">
              <img
                src={
                  isStreamer() ? r.streamer.streamer.images[0] : r.user.imgUrl
                }
              />
            </div>
            <div className="relative break-words mr-3 text-left text-sm bg-purple-100 py-2 px-4 shadow rounded-xl">
              <div>{msg.content}</div>
            </div>
          </div>
          <p className="text-xs text-left mr-3 font-lato">
            {moment(msg.createdAt, "x").format("lll")}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-full mt-4 lg:mt-0 flex">
      <div className={`flex flex-col rounded-lg ${bgColor} h-full w-full py-4`}>
        <div className="flex flex-col mb-4 overflow-x-auto h-full">
          <div className="flex flex-col h-full">
            {chats && chats.length ? (
              <div className="flex flex-col content-start h-full">
                {chats
                  .sort((x, y) => x.createdAt - y.createdAt)
                  .map((msg) => {
                    if (isStreamer()) {
                      if (msg.author === "user") {
                        return leftSideMsgs(msg, room);
                      }
                      return rightSideMsgs(msg, room);
                    }
                    if (msg.author === "user") {
                      return rightSideMsgs(msg, room);
                    }
                    return leftSideMsgs(msg, room);
                  })}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
          <div className="flex-grow ml-4">
            <div className="relative w-full">
              <input
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                value={newMsg}
                onChange={(e) => setNewMsg(e.target.value)}
                type="text"
                className="flex w-full border rounded-xl focus:outline-none focus:border-purple-300 pl-4 h-11"
              />
            </div>
          </div>
          <div className="ml-4">
            {textSendBtn ? (
              <BtnClassic
                disabled={newMsg.length < 1}
                action={sendMessage}
                text="Envoyer"
                logo={<MdSend size="18" />}
              />
            ) : (
              <div
                className={`bg-gray-100 hover:bg-gray-200 transition-all p-3 rounded-lg ${
                  newMsg.length < 1 ? "cursor-default" : "cursor-pointer"
                }`}
                disabled={newMsg.length < 1}
                onClick={sendMessage}
              >
                <MdSend
                  color={newMsg.length < 1 ? "#e5e7eb" : "#1f2937"}
                  size="18"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatMessagesInCall;
