import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  collection,
  limit,
  orderBy,
  startAt,
  endAt,
  startAfter,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../firebase";
import randomId from "../../RandomId";

// Utils
const fetchUserAndStreamer = async (data) => {
  let response = { ...data };
  if (data.streamerRef) {
    const streamerSnap = await getDoc(data.streamerRef);
    if (streamerSnap.exists()) {
      response = { ...response, streamer: streamerSnap.data() };
    }
  }
  if (data.userRef) {
    const userSnap = await getDoc(data.userRef);
    if (userSnap.exists()) {
      response = { ...response, user: userSnap.data() };
    }
  }
  return response;
};

async function instantMeetingSpecificGet(createdAt, user, streamer) {
  if (!user || !streamer || !createdAt) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  const q = query(
    meetingsRef,
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
    where("userRef", "==", doc(db, "users", user.uid)),
    where("createdAt", "==", createdAt),
  );
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );

  return result[0];
}

async function instantMeetingCreate(user, streamer) {
  if (!user || !streamer) {
    return false;
  }
  const newMeeting = {
    streamerRef: doc(db, "users", streamer.uid),
    userRef: doc(db, "users", user.uid),
    answered: false, // If the streamer as give a response (accepted or refused)
    createdAt: parseInt(moment().format("x")),
    priceMinute: parseFloat(streamer.streamer.price),
    status: "WAITING",
  };
  const meetingDoc = doc(db, "instantMeetings", randomId());
  if (meetingDoc) {
    return setDoc(meetingDoc, newMeeting, { merge: true });
  }
  return false;
}

async function instantMeetingGetById(id) {
  if (!id) {
    return false;
  }
  const meetingDoc = doc(db, "instantMeetings", id);
  const docSnap = await getDoc(meetingDoc);
  let res;
  if (docSnap.exists()) {
    res = await fetchUserAndStreamer(docSnap.data());
  }
  return res;
}

async function instantMeetingSetChoice(meet, bool = false) {
  if (!meet) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  const q = query(meetingsRef, where("createdAt", "==", meet.createdAt));
  const querySnap = await getDocs(q);
  let meetFinal = null;
  querySnap.forEach((docc) => {
    meetFinal = docc;
  });
  console.log(meetFinal.ref);
  // meetFinal = await getDoc(meetFinal.ref);
  await setDoc(
    meetFinal.ref,
    {
      answered: true,
      answer: bool,
      acceptedAt: parseInt(moment().format("x")),
      status: bool ? "LIVE" : "ENDED",
    },
    { merge: true },
  );
}

async function instantMeetingUpdateStatus(id, status) {
  if (!id || !status) {
    return false;
  }
  const meetingDoc = doc(db, "instantMeetings", id);
  if (meetingDoc) {
    return updateDoc(meetingDoc, { status }, { merge: true });
  }
}

async function instantMeetingAddNote(id, note) {
  if (!id || !note) {
    return false;
  }
  const meetingDoc = doc(db, "instantMeetings", id);
  if (meetingDoc) {
    return setDoc(
      meetingDoc,
      {
        rating: note,
      },
      { merge: true },
    );
  }
}

async function instantMeetingsGetByUserPagination(
  user,
  { min, max },
  sa = null,
) {
  if (!user) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  let q;
  if (sa) {
    const meetingRef = doc(db, "instantMeetings", sa.id);
    const docSnap = await getDoc(meetingRef);
    if (docSnap.exists()) {
      q = query(
        meetingsRef,
        where("userRef", "==", doc(db, "users", user.uid)),
        limit(max),
        orderBy("createdAt", "desc"),
        startAfter(docSnap),
      );
    }
  } else {
    q = query(
      meetingsRef,
      where("userRef", "==", doc(db, "users", user.uid)),
      orderBy("createdAt", "desc"),
      limit(max),
    );
  }
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );
  return result;
}

async function instantMeetingUpdateTimeUsed(id, usedMinutes) {
  if (!id || !usedMinutes) {
    return false;
  }
  const meetingDoc = doc(db, "instantMeetings", id);
  if (meetingDoc) {
    return updateDoc(meetingDoc, { usedMinutes }, { merge: true });
  }
}

async function instantMeetingSetView(id, view = true) {
  if (!id) {
    return false;
  }
  const meetingRef = doc(db, "instantMeetings", id);
  await setDoc(meetingRef, { view }, { merge: true });
}

async function instantMeetingsGetByStreamer(user) {
  if (!user) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  const q = query(
    meetingsRef,
    where("streamerRef", "==", doc(db, "users", user.uid)),
  );
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );

  return result;
}

async function instantMeetingsGetByStreamerAndUser(
  user,
  streamer,
  status = "ENDED",
) {
  if (!user || !streamer) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  const q = query(
    meetingsRef,
    where("userRef", "==", doc(db, "users", user.uid)),
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
    where("status", "==", status),
  );
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    console.log(el);
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });
  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );

  return result;
}

async function instantMeetingsGetByUser(user) {
  if (!user) {
    return false;
  }
  const meetingsRef = collection(db, "instantMeetings");
  const q = query(
    meetingsRef,
    where("userRef", "==", doc(db, "users", user.uid)),
  );
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );

  return result;
}

export {
  instantMeetingCreate,
  instantMeetingsGetByUser,
  instantMeetingsGetByStreamer,
  fetchUserAndStreamer,
  instantMeetingSetView,
  instantMeetingSetChoice,
  instantMeetingGetById,
  instantMeetingSpecificGet,
  instantMeetingUpdateStatus,
  instantMeetingUpdateTimeUsed,
  instantMeetingsGetByUserPagination,
  instantMeetingAddNote,
  instantMeetingsGetByStreamerAndUser,
};
