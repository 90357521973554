import React, { useRef, useState } from "react";

import BtnClassic from "../buttons/BtnClassic";

function ModalSelectDevicesRoom({
  setShowModal,
  showModal,
  videoActualDevice,
  audioActualDevice,
  videoDevices,
  audioDevices,
  changeAudioDevice,
  changeVideoDevice,
  setActualAudioDevice,
  setActualVideoDevice,
}) {
  const bgModal = useRef(null);
  const [loading, setLoading] = useState(false);

  /*
   * Hide/Display modal
   */
  const toggleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (bgModal.current === e.target) {
      setShowModal(!showModal);
    }
  };

  const successAction = () => {
    setLoading(true);
    try {
      changeAudioDevice(audioActualDevice);
      changeVideoDevice(videoActualDevice);
    } catch (err) {
      console.log("Error ", err);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        onKeyDown={toggleModal}
        role="button"
        tabIndex={0}
        ref={bgModal}
        onClick={toggleModal}
        className={`justify-center h-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none transition-all z-50 ease-in-out duration-300 ${
          showModal ? "opacity-100 scale-100" : "opacity-0 scale-0"
        }`}
      >
        <div className="relative w-auto my-6 mx-6 sm:mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg px-8 pt-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between rounded-t">
              <h3 className="text-lg font-bold leading-6 text-gray-900">
                Modifier vos appareils
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative flex-auto mb-10">
              <p className="my-4 text-slate-900 text-lg text-left leading-relaxed">
                Veuillez sélectionner les appareils à utiliser pour ce call.
              </p>
              <div className="flex flex-col space-y-6">
                {audioActualDevice ? (
                  <div className="flex flex-col">
                    <label htmlFor="audioDevices" className="label">
                      <span className="label-text text-text">Audio</span>
                    </label>
                    <select
                      className="w-full rounded-lg h-11 bg-white outline-0 pl-4 focus:ring-0 border focus:border-fuchsia-500 border-text"
                      id="audioDevices"
                      value={audioActualDevice}
                      onChange={(e) => setActualAudioDevice(e.target.value)}
                    >
                      {audioDevices &&
                        audioDevices.map((el) => (
                          <option key={el.deviceId} value={el.deviceId}>
                            {el.label}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : (
                  <div className="bg-purple-200 p-4 rounded text-purple-700 font-semibold">
                    Aucun appareil audio actuellement disponible.
                  </div>
                )}
                {videoActualDevice ? (
                  <div className="flex flex-col">
                    <label htmlFor="videoDevices" className="label">
                      <span className="label-text text-text">Video</span>
                    </label>
                    <select
                      className="w-full rounded-lg h-11 bg-white outline-0 pl-4 focus:ring-0 border focus:border-fuchsia-500 border-text"
                      value={videoActualDevice}
                      id="videoDevices"
                      onChange={(e) => setActualVideoDevice(e.target.value)}
                    >
                      {videoDevices &&
                        videoDevices.map((el) => (
                          <option key={el.deviceId} value={el.deviceId}>
                            {el.label}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : (
                  <div className="bg-purple-200 p-4 rounded text-purple-700 font-semibold">
                    Aucun appareil vidéo actuellement disponible.
                  </div>
                )}
              </div>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end py-4 border-t border-solid border-slate-200 rounded-b">
              <div className="space-x-2 flex flex-row">
                <BtnClassic
                  color="cancel"
                  text="Annuler"
                  action={() => setShowModal(false)}
                />
                <BtnClassic
                  width="w-44"
                  color="success"
                  text="Valider"
                  action={successAction}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`top-0 left-0 w-screen h-screen absolute bg-modalBg z-40 ${
          showModal ? "" : "hidden"
        }`}
      />
    </>
  );
}

export default ModalSelectDevicesRoom;
