import React, { useEffect, useState } from "react";

function BtnClassic({
  text,
  action,
  logo = null,
  color = "primary",
  disabled = false,
  loading = false,
  width = null,
  keyText = null,
  innerRef = null,
  icon = null,
}) {
  const [useColor, setUseColor] = useState(color);
  const [useHoverColor, setUseHoverColor] = useState(color);

  useEffect(() => {
    switch (color) {
      case "success":
        setUseColor("bg-emerald-500");
        setUseHoverColor("hover:bg-emerald-700");
        break;
      case "gradient-primary":
        setUseColor("bg-gradient-to-r from-fuchsia-500 to-purple-500");
        setUseHoverColor(
          "bg-gradient-to-r hover:from-fuchsia-700 hover:to-purple-700",
        );
        break;
      case "cancel":
        setUseColor("bg-slate-500");
        setUseHoverColor("hover:bg-slate-700");
        break;
      case "danger":
        setUseColor("bg-red-500");
        setUseHoverColor("hover:bg-red-700");
        break;
      case "primary":
        setUseColor("bg-fuchsia-500");
        setUseHoverColor("hover:bg-fuchsia-700");
        break;
      case "skyblue":
        setUseColor("bg-sky-500");
        setUseHoverColor("hover:bg-sky-700");
        break;
      default:
        setUseColor("bg-sky-500");
        setUseHoverColor("hover:bg-sky-700");
        break;
    }
  });
  return (
    <div
      role="button"
      tabIndex={0}
      ref={innerRef}
      disabled={disabled}
      onClick={action}
      className={`btn ${useColor} ${useHoverColor} ${
        loading ? "loading" : ""
      } ${
        width || "w-fit"
      } flex flex-row flex-nowrap gap-3 font-bold px-6 relative`}
    >
      {icon}
      <kbd
        className={`kbd text-text font-ui-monospace font-regular mt-0.5 absolute left-2.5 ${
          !!keyText && !icon ? "" : "hidden"
        }`}
      >
        {keyText}
      </kbd>
      <span className="pt-0.5">{text}</span>
      {logo || ""}
    </div>
  );
}

export default BtnClassic;
