import { collection, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";

import { db } from "../../utils/Firebase/firebase";

function NotifLiveComp({ room, notif, setNotif, messagesRoom }) {
  const user = useSelector((state) => state.user);
  /**
   * Automaticly fetch messages (all time in sync)
   * https://github.com/CSFrequency/react-firebase-hooks/blob/master/firestore/README.md
   */
  const [chats] = useCollectionData(
    query(
      collection(db, "chatRooms", room.id, "chats"),
      orderBy("createdAt", "desc"),
      limit(25),
    ),
  );

  useEffect(() => {
    if (chats && chats.length && user && user.uid && !messagesRoom) {
      setNotif(true);
    }
  }, [chats]);

  return (
    <div>
      {notif ? (
        <div className="absolute shadow-lg -top-1 -right-1 inline-flex items-center rounded-full w-4 h-4 text-sm font-semibold text-white bg-gradient-to-br from-red-600 to-orange-400">
          {" "}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default NotifLiveComp;
