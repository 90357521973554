import React, { useRef } from "react";
import BtnClassic from "../buttons/BtnClassic";

function ModalTemplate({
  setShowModal,
  showModal,
  title,
  desc,
  successAction,
  successColor = "success",
  errorColor = "cancel",
  successActionTitle = "Supprimer",
  errorActionTitle = "Annuler",
  widthSuccessBtn = "w-44",
  loading = false,
}) {
  const bgModal = useRef(null);

  /*
   * Hide/Display modal
   */
  const toggleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (bgModal.current === e.target) {
      setShowModal(!showModal);
    }
  };
  return (
    <>
      <div
        onKeyDown={toggleModal}
        role="button"
        tabIndex={0}
        ref={bgModal}
        onClick={toggleModal}
        className={`justify-center h-full items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none transition-all z-50 ease-in-out duration-300 ${
          showModal ? "opacity-100 scale-100" : "opacity-0 scale-0"
        }`}
      >
        <div className="relative w-auto my-6 mx-6 sm:mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg px-8 pt-6 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between rounded-t">
              <h3 className="text-lg font-bold leading-6 text-gray-900">
                {title}
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative flex-auto">
              <p className="my-4 text-slate-900 text-lg text-left leading-relaxed">
                {desc}
              </p>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end py-4 border-t border-solid border-slate-200 rounded-b">
              <div className="space-x-2 flex flex-row">
                <BtnClassic
                  color={errorColor}
                  text={errorActionTitle}
                  action={() => setShowModal(false)}
                />
                <BtnClassic
                  width={widthSuccessBtn}
                  color={successColor}
                  text={successActionTitle}
                  action={successAction}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`top-0 left-0 w-screen h-screen absolute bg-modalBg z-40 ${
          showModal ? "" : "hidden"
        }`}
      />
    </>
  );
}

export default ModalTemplate;
