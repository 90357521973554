import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    numberMessages: "",
    numberMeetings: "",
  },
  reducers: {
    setNumber: (state, { payload }) => {
      if (payload.numberMessages) {
        state.numberMessages = payload.numberMessages;
      }
      if (payload.numberMeetings) {
        state.numberMeetings = payload.numberMeetings;
      }
    },
  },
});

export const { setNumber } = notificationsSlice.actions;

export default notificationsSlice.reducer;
