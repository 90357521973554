import React, { memo } from "react";

function Timer({ minutesLeft, secondesLeft, hoursLeft = null }) {
  return (
    <div id="timer-el">
      <div className="grid grid-flow-col gap-2 text-center auto-cols-max">
        {hoursLeft && hoursLeft > 0 ? (
          <div className="flex shadow-xl flex-col p-2 lg:p-4 bg-gradient-to-tl from-fuchsia-500 to-purple-500 rounded-box text-neutral-content">
            <span className="countdown font-sans text-5xl">
              <span style={{ "--value": hoursLeft }} />
            </span>
            heures
          </div>
        ) : (
          ""
        )}
        <div className="flex shadow-xl flex-col p-2 lg:p-4 bg-gradient-to-tl from-fuchsia-500 to-purple-500 rounded-box text-neutral-content">
          <span className="countdown font-sans text-5xl">
            <span style={{ "--value": minutesLeft }} />
          </span>
          min
        </div>
        <div className="flex shadow-xl flex-col p-2 lg:p-4 bg-gradient-to-tr from-fuchsia-500 to-purple-500 rounded-box text-neutral-content">
          <span className="countdown font-sans text-5xl">
            <span style={{ "--value": secondesLeft }} />
          </span>
          sec
        </div>
      </div>
    </div>
  );
}

export default memo(Timer);
