import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    uid: "",
    email: "",
    password: "",
    displayName: "",
    phone: "",
    imgUrl: "",
    amount: 0,
    streamerTitle: "",
    obvyId: "",
    ban: false,
    admin: false,
    firstName: "",
    lastName: "",
    partner: false,
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.uid = payload.uid;
      state.email = payload.email;
      state.displayName = payload.displayName;
      state.phone = payload.phone;
      state.imgUrl = payload.imgUrl;
      state.amount = payload.amount;
      state.streamerTitle = payload.streamerTitle;
      state.ban = payload.ban;
      state.admin = payload.admin;
      state.obvyId = payload.obvyId;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.partner = payload.partner;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
