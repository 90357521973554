import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  collection,
} from "firebase/firestore";
import { db } from "../firebase";
import randomId from "../../RandomId";

async function roomCreate({ meetId, streamer, user }) {
  const sessionId = randomId();
  const roomsDoc = doc(db, "rooms", sessionId);
  if (roomsDoc) {
    setDoc(
      roomsDoc,
      {
        streamer,
        user,
        meetId,
      },
      { merge: true },
    );
    return sessionId;
  }
}

async function roomGet(id) {
  const roomsDoc = doc(db, "rooms", id);
  if (roomsDoc) {
    const docSnap = await getDoc(roomsDoc);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    return false;
  }
}

async function roomGetByMeetId(id) {
  if (!id) {
    return false;
  }
  const roomsRef = collection(db, "rooms");
  const q = query(roomsRef, where("meetId", "==", doc(db, "meetings", id)));
  const querySnap = await getDocs(q);
  let data;
  querySnap.forEach((el) => (data = { ...el.data(), id: el.id }));
  return data;
}

async function roomGetByInstantMeetId(id) {
  if (!id) {
    return false;
  }
  const roomsRef = collection(db, "rooms");
  const q = query(
    roomsRef,
    where("instantMeetingId", "==", doc(db, "instantMeetings", id)),
  );
  const querySnap = await getDocs(q);
  let data;
  querySnap.forEach((el) => (data = { ...el.data(), id: el.id }));
  return data;
}

async function roomGetParticipantsById(id) {
  const participantsCollection = collection(db, "rooms", id, "participants");
  if (participantsCollection) {
    const docSnap = await getDocs(participantsCollection);
    const participants = [];
    docSnap.forEach((el) => {
      participants.push(el.data());
    });
    return participants;
  }
}

async function roomGetParticipantById(id, userId) {
  const participantsCollection = collection(db, "rooms", id, "participants");
  if (participantsCollection) {
    const q = query(participantsCollection, where("userId", "==", userId));
    const docSnap = await getDocs(q);
    let participant;
    docSnap.forEach((el) => (participant = el.data()));
    return participant;
  }
}

async function roomJoin({ userId, roomId }) {
  const roomsDoc = doc(db, "rooms", roomId, "participants", userId);
  if (roomsDoc) {
    return setDoc(roomsDoc, { userId }, { merge: true });
  }
}

export {
  roomJoin,
  roomCreate,
  roomGet,
  roomGetParticipantsById,
  roomGetByMeetId,
  roomGetByInstantMeetId,
  roomGetParticipantById,
};
