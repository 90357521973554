import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  getDoc,
  collection,
  limit,
  orderBy,
  startAt,
  endAt,
  startAfter,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../firebase";
import randomId from "../../RandomId";

// Utils
const fetchUserAndStreamer = async (data) => {
  let response = { ...data };
  if (data.streamerRef) {
    const streamerSnap = await getDoc(data.streamerRef);
    if (streamerSnap.exists()) {
      response = { ...response, streamer: streamerSnap.data() };
    }
  }
  if (data.userRef) {
    const userSnap = await getDoc(data.userRef);
    if (userSnap.exists()) {
      response = { ...response, user: userSnap.data() };
    }
  }
  return response;
};

// Firebase functions

async function meetingsGetByStreamerWithSpecialDateTime(user, timeStamps) {
  if (!user && (!timeStamps || !timeStamps.length)) {
    return false;
  }
  const meetingsRef = collection(db, "meetings");
  const res = await Promise.all(
    timeStamps.map(async (ts) => {
      const q = query(
        meetingsRef,
        where("streamerRef", "==", doc(db, "users", user.uid)),
        where("timestamp", "<=", ts),
        where("remove", "==", false),
      );
      const querySnap = await getDocs(q);
      let data = null;
      querySnap.forEach((el) => {
        if (el.data().timestampEnd > ts) {
          data = el.data();
        }
        console.log(data);
      });
      return data;
    }),
  );
  return res && res[0];
}

async function meetingsGetByStreamer(user, remove = true) {
  if (!user) {
    return false;
  }
  const meetingsRef = collection(db, "meetings");
  const queries = [where("streamerRef", "==", doc(db, "users", user.uid))];
  if (remove) {
    queries.push(where("remove", "!=", true));
  }
  const q = query(meetingsRef, ...queries);
  const querySnap = await getDocs(q);
  const meetings = [];
  let i = 1;
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id, index: i });
    i++;
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );
  return result;
}

async function meetingsGetByStreamerAndUser(
  user,
  streamer,
  status = "ENDED",
  remove = true,
) {
  if (!user || !streamer) {
    return false;
  }
  const meetingsRef = collection(db, "meetings");
  const queries = [
    where("userRef", "==", doc(db, "users", user.uid)),
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
    where("status", "==", status),
  ];
  if (remove) {
    queries.push(where("remove", "!=", true));
  }
  const q = query(meetingsRef, ...queries);
  const querySnap = await getDocs(q);
  const meetings = [];
  let i = 1;
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id, index: i });
    i++;
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );
  return result;
}

async function meetingGetNext(user, ts) {
  if (!user) {
    return false;
  }
  console.log(user, ts);
  const q = query(
    collection(db, "meetings"),
    where("streamerRef", "==", doc(db, "users", user.uid)),
    where("timestampEnd", ">", ts),
    where("remove", "==", false),
    orderBy("timestampEnd", "asc"),
  );
  const querySnap = await getDocs(q);
  let data = null;
  querySnap.forEach((el) => {
    console.log(data);
    if (!data) {
      data = { ...el.data(), id: el.id };
    }
  });
  return data;
}

async function meetingSetView(id, view = true) {
  if (!id) {
    return false;
  }
  const meetingRef = doc(db, "meetings", id);
  await setDoc(meetingRef, { view }, { merge: true });
}

async function meetingsGetByUser(user, { min, max }, sa = null) {
  if (!user) {
    return false;
  }
  const meetingsRef = collection(db, "meetings");
  let q;
  if (sa) {
    const meetingRef = doc(db, "meetings", sa.id);
    const docSnap = await getDoc(meetingRef);
    if (docSnap.exists()) {
      q = query(
        meetingsRef,
        where("userRef", "==", doc(db, "users", user.uid)),
        limit(max),
        orderBy("timestamp", "desc"),
        startAfter(docSnap),
      );
    }
  } else {
    q = query(
      meetingsRef,
      where("userRef", "==", doc(db, "users", user.uid)),
      orderBy("timestamp", "desc"),
      limit(max),
    );
  }
  const querySnap = await getDocs(q);
  const meetings = [];
  querySnap.forEach((el) => {
    const data = el.data();
    meetings.push({ ...data, id: el.id });
  });

  const result = await Promise.all(
    meetings.map(async (meet) => {
      const res = await fetchUserAndStreamer(meet);
      return res;
    }),
  );
  return result;
}

async function meetingAddStreamer(user, meeting) {
  console.log(meeting);
  if (!user || !meeting) {
    return false;
  }
  const newMeeting = {
    ...meeting,
    streamerRef: doc(db, "users", meeting.streamerRef),
    userRef: doc(db, "users", meeting.userRef),
    remove: false,
  };
  const meetingDoc = doc(db, "meetings", randomId());
  if (meetingDoc) {
    return setDoc(meetingDoc, newMeeting, { merge: true });
  }
  return false;
}

async function meetingGetById(id) {
  if (!id) {
    return false;
  }
  const meetingDoc = doc(db, "meetings", id);
  const docSnap = await getDoc(meetingDoc);
  let res;
  if (docSnap.exists()) {
    res = await fetchUserAndStreamer(docSnap.data());
  }
  return res;
}

async function meetingGetEvents(idMeeting) {
  if (!idMeeting) {
    return false;
  }
  const eventsRef = collection(db, "events");

  const q = query(
    eventsRef,
    where("meeting", "==", doc(db, "meetings", idMeeting)),
  );
  const querySnap = await getDocs(q);
  const events = [];
  querySnap.forEach((el) => {
    events.push(el.data());
  });
  return events;
}

async function meetingUpdateStatus(id, status) {
  if (!id || !status) {
    return false;
  }
  const meetingDoc = doc(db, "meetings", id);
  if (meetingDoc) {
    return updateDoc(meetingDoc, { status }, { merge: true });
  }
}

async function meetingSetRemove(id) {
  if (!id) {
    return false;
  }
  const meetingDoc = doc(db, "meetings", id);
  if (meetingDoc) {
    return updateDoc(meetingDoc, { remove: true }, { merge: true });
  }
}

async function meetingAddNote(id, note) {
  if (!id || !note) {
    return false;
  }
  const meetingDoc = doc(db, "meetings", id);
  if (meetingDoc) {
    return setDoc(
      meetingDoc,
      {
        rating: note,
      },
      { merge: true },
    );
  }
}

async function meetingGetAverageNoteForStreamer(streamer) {
  if (!streamer) {
    return false;
  }
  const q = query(
    collection(db, "meetings"),
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
  );
  const snap = await getDocs(q);
  const notes = [];
  snap.forEach((el) => {
    if (el.data().rating) {
      notes.push(el.data().rating);
    }
  });
  const sum = notes.reduce((a, b) => a + b, 0);
  const avg = sum / notes.length || 0;
  return avg;
}

async function meetingsGetNotViewByStreamer(streamer) {
  if (!streamer) {
    return false;
  }
  const q = query(
    collection(db, "meetings"),
    where("streamerRef", "==", doc(db, "users", streamer.uid)),
    where("view", "==", false),
    where("timestamp", "<", moment().format("x")),
  );
  const snap = await getDocs(q);
  const datas = [];
  snap.forEach((el) => {
    if (el.data()) {
      datas.push(el.data());
    }
  });
  return datas;
}

export {
  meetingUpdateStatus,
  meetingGetEvents,
  meetingGetById,
  meetingAddStreamer,
  meetingsGetByStreamer,
  meetingsGetByStreamerWithSpecialDateTime,
  meetingsGetByUser,
  meetingAddNote,
  meetingGetAverageNoteForStreamer,
  meetingSetRemove,
  meetingGetNext,
  meetingSetView,
  meetingsGetNotViewByStreamer,
  meetingsGetByStreamerAndUser,
};
