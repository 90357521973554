import {
  doc,
  setDoc,
  updateDoc,
  query,
  where,
  getDocs,
  limit,
  getDoc,
  collection,
} from "firebase/firestore";
import { db } from "../firebase";
import randomId from "../../RandomId";

function userCheckEntries(user) {
  const uid = user && user.uid ? user.uid : "";
  const phone = user && user.phone ? user.phone : "";
  const email = user && user.email ? user.email : "";
  const displayName = user && user.displayName ? user.displayName : "";
  const imgUrl = user && user.imgUrl ? user.imgUrl : "";
  const amount = user && user.amount ? user.amount : 0;
  const streamerTitle = user && user.streamerTitle ? user.streamerTitle : "";
  const ban = user && user.ban ? user.ban : false;
  const admin = user && user.admin ? user.admin : false;
  const obvyId = user && user.obvyId ? user.obvyId : "";
  const firstName = user && user.firstName ? user.firstName : "";
  const lastName = user && user.lastName ? user.lastName : "";
  const partner = user && user.partner ? user.partner : false;
  return {
    phone,
    obvyId,
    amount,
    streamerTitle,
    email,
    displayName,
    imgUrl,
    uid,
    ban,
    admin,
    firstName,
    lastName,
    partner,
  };
}

async function userSetData(user) {
  if (!user.uid) {
    return false;
  }
  const userDoc = doc(db, "users", user.uid);
  if (userDoc) {
    const { email, displayName, phone, imgUrl, uid } = userCheckEntries(user);
    return setDoc(
      userDoc,
      {
        email,
        displayName,
        phone,
        imgUrl,
        uid,
        mark: 0,
        privacyPolicyValue: user.privacyPolicyValue
          ? user.privacyPolicyValue
          : false,
      },
      { merge: true },
    );
  }
}

async function userGetByStreamerTitle(title) {
  if (!title) {
    return false;
  }
  const usersRef = collection(db, "users");

  const q = query(usersRef, where("streamerTitle", "==", title));
  const querySnap = await getDocs(q);
  let data = [];
  querySnap.forEach((el) => (data = el.data()));
  return data;
}

async function userUpdateData(user) {
  if (!user.uid) {
    return false;
  }
  const userDoc = doc(db, "users", user.uid);
  if (userDoc) {
    const { email, displayName, phone, imgUrl, firstName, lastName } =
      userCheckEntries(user);
    return updateDoc(
      userDoc,
      { email, displayName, phone, imgUrl, firstName, lastName },
      { merge: true },
    );
  }
}

async function userUpdateEmail(uid, email) {
  if (!uid) {
    return false;
  }
  const userDoc = doc(db, "users", uid);
  if (userDoc) {
    return updateDoc(userDoc, { email }, { merge: true });
  }
}

async function userBan(user, bool) {
  if (!user.uid) {
    return false;
  }
  const userDoc = doc(db, "users", user.uid);
  if (userDoc) {
    return updateDoc(userDoc, { ban: bool }, { merge: true });
  }
}

async function userGetById(id) {
  const userDoc = doc(db, "users", id);
  const docSnap = await getDoc(userDoc);
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return false;
}

async function userGetByDisplayName(displayName) {
  const usersRef = collection(db, "users");

  const q = query(usersRef, where("displayName", "==", displayName));
  const querySnap = await getDocs(q);
  let bool = false;
  querySnap.forEach(() => {
    bool = true;
  });
  return bool;
}

async function usersGet() {
  const usersRef = collection(db, "users");
  const q = query(usersRef, limit(20));
  const querySnap = await getDocs(q);
  const data = [];
  querySnap.forEach((el) => {
    data.push(el.data());
  });
  return data;
}

export {
  userSetData,
  userUpdateData,
  userGetByDisplayName,
  userCheckEntries,
  userGetById,
  userGetByStreamerTitle,
  usersGet,
  userBan,
  userUpdateEmail,
};
