import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";
import InstantMeetingLive from "./pages/InstantMeeting/InstantMeetingLive";

const LoginPage = lazy(() => import("./pages/LoginPage"));
const ChatPage = lazy(() => import("./pages/ChatPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const WalletPage = lazy(() => import("./pages/WalletPage"));
const DashboardAdmin = lazy(() => import("./pages/Admin/DashboardAdmin"));
const ListUserAdmin = lazy(() => import("./pages/Admin/ListUserAdmin"));
const MeetsAdmin = lazy(() => import("./pages/Admin/MeetsAdmin"));
const ReportsAdmin = lazy(() => import("./pages/Admin/ReportsAdmin"));
const KnowMore = lazy(() => import("./pages/KnowMore"));
const UtilisationConditions = lazy(() =>
  import("./pages/Legals/UtilisationConditions"),
);
const ConfidentialsPolicy = lazy(() =>
  import("./pages/Legals/ConfidentialsPolicy"),
);
const DetailsUserAdmin = lazy(() => import("./pages/Admin/DetailsUserAdmin"));
const UserRoute = lazy(() => import("./utils/UserRoute"));
const StreamerRoute = lazy(() => import("./utils/StreamerRoute"));
const AdminRoute = lazy(() => import("./utils/AdminRoute"));
const PaymentChoicePage = lazy(() =>
  import("./pages/Wallet/PaymentChoicePage"),
);
const PaymentValidate = lazy(() => import("./pages/Wallet/PaymentValidate"));
const RegisterStreamer = lazy(() =>
  import("./pages/Streamer/RegisterStreamer"),
);
const DashboardStreamer = lazy(() =>
  import("./pages/Streamer/DashboardStreamer"),
);
const ProfileStreamer = lazy(() => import("./pages/Streamer/ProfileStreamer"));
const AgendaStreamer = lazy(() => import("./pages/Streamer/AgendaStreamer"));
const DetailsMeeting = lazy(() => import("./pages/Streamer/DetailsMeeting"));
const ParametersStreamer = lazy(() =>
  import("./pages/Streamer/ParametersStreamer"),
);
const ChatStreamer = lazy(() => import("./pages/Streamer/ChatStreamer"));
const StatsStreamer = lazy(() => import("./pages/Streamer/StatsStreamer"));
const StreamerDetailsV2 = lazy(() => import("./pages/StreamerDetailsV2"));
const MeetingsPage = lazy(() => import("./pages/MeetingsPage"));
const MeetingLive = lazy(() => import("./pages/Meeting/MeetingLive"));
const PartnerFormStreamer = lazy(() =>
  import("./pages/Streamer/PartnerFormStreamer"),
);
const PartnerPage = lazy(() => import("./pages/Admin/PartnerPage"));

function RoutesApp({ display }) {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {display ? (
          <Route element={<UserRoute />}>
            <Route element={<StreamerRoute />}>
              <Route element={<AdminRoute />}>
                <Route path="/admin/dashboard" element={<DashboardAdmin />} />
                <Route path="/admin/utilisateurs" element={<ListUserAdmin />} />
                <Route path="/admin/meets" element={<MeetsAdmin />} />
                <Route path="/admin/reports" element={<ReportsAdmin />} />
                <Route path="/admin/partenaires" element={<PartnerPage />} />
                <Route
                  path="/admin/utilisateurs/:id"
                  element={<DetailsUserAdmin />}
                />
              </Route>
              <Route path="/streamer/accueil" element={<DashboardStreamer />} />
              <Route path="/streamer/profil" element={<ProfileStreamer />} />
              <Route
                path="/streamer/statistiques"
                element={<StatsStreamer />}
              />
              <Route
                path="/streamer/agenda/meeting/:id"
                element={<DetailsMeeting />}
              />
              <Route path="/streamer/agenda/*" element={<AgendaStreamer />} />
              <Route path="/streamer/messagerie" element={<ChatStreamer />} />
            </Route>
            <Route path="/streamer/register" element={<RegisterStreamer />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route
              path="/wallet/payment-choice"
              element={<PaymentChoicePage />}
            />
            <Route path="/wallet/validate" element={<PaymentValidate />} />
            <Route path="/profil" element={<ProfilePage />} />
            <Route path="/meetings" element={<MeetingsPage />} />
            <Route path="/messagerie" element={<ChatPage />} />
            <Route
              path="/details/streamer/:id"
              element={<StreamerDetailsV2 />}
            />
            <Route path="/meeting/:id" element={<MeetingLive />} />
            <Route
              path="/instantMeeting/:id"
              element={<InstantMeetingLive />}
            />
            <Route
              path="/streamer/parametres"
              element={<ParametersStreamer />}
            />
            <Route
              path="/streamer/accueil/partenaire"
              element={<PartnerFormStreamer />}
            />
          </Route>
        ) : (
          ""
        )}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/en-savoir-plus" element={<KnowMore />} />
        <Route
          path="/utilisation-conditions"
          element={<UtilisationConditions />}
        />
        <Route path="/confidentials-policy" element={<ConfidentialsPolicy />} />
      </Routes>
    </Suspense>
  );
}

export default RoutesApp;
